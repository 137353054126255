import React from "react"
import Leaflet from 'leaflet'
import { Marker, Popup } from 'react-leaflet'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import PopupData from "./PopupData";
import { getcolorclass } from "../../constants";
import SearchableDropdown from "../SearchableDropdown";

const MapMultipleMarkers = ({onMouseOver}) => {
  const navigate = useNavigate();
  const { items } = useSelector((state) => state.getapiData);
  // console.log(items, 'markers')
  return items ? items.map((item, index) => {
    let customMarkerIcon = ''
    if (item?.id == '5fdb0bc345047a4d3d83d677') {
      customMarkerIcon = new Leaflet.DivIcon({
        html: '<b class="custom-cluster-icon custom-cluster-icon-' + getcolorclass('color', item?.historical?.hourly[0]?.outdoor_station?.aqius) + '">' + item?.historical?.hourly[0]?.outdoor_station?.aqius + '</b>',
      });
    }
    else {
      customMarkerIcon = new Leaflet.DivIcon({
        html: '<b class="custom-cluster-icon custom-cluster-icon-' + getcolorclass('color', item?.current?.aqius) + '">' + item?.current?.aqius + '</b>',
      });
    }

    return (<Marker
      key={index} icon={customMarkerIcon} eventHandlers={{
        mouseover: (event) => {
          onMouseOver(true);
          event.target.openPopup()
        },
        mouseout: (event) => {
          event.target.closePopup()
          onMouseOver(false);
        },
        click: (event) => { window.open(`/detailpage/${item.id}`, '_blank');  },
      }} position={[item.lat, item.lang]}>
      <Popup>
        <PopupData data={item} />
      </Popup>
    </Marker>

    )
  }) : ''
}
export default MapMultipleMarkers
