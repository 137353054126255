import { Link } from "react-router-dom"
import HomePage from "../Home/homePage"

const Header = () => {

  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <a className="navbar-brand" href='https://pcaakp.org/'><img className="logo-main" src="https://pcaakp.org/wp-content/uploads/2021/12/logo-teal.png" /></a>
        {/* <a href="https://pcaakp.org/"><img className="logo-main" src="https://pcaakp.org/wp-content/uploads/2021/12/logo-teal.png" /></a> */}
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <a className="nav-link" href="https://pcaakp.org/">Home <span className="sr-only">(current)</span></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://pcaakp.org/what-we-do/">What we Do</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://pcaakp.org/resources/">Resources</a>
            </li>
            <li className="nav-item">
              <a className="nav-link " href="https://pcaakp.org/donate/">Donate</a>
            </li>
            <li className="nav-item">
              <a className="nav-link " href="https://pcaakp.org/join-us/">Join Us</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default Header