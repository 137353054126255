/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

import { tooltipContent } from "../../constants";
import { wpBaseUrl, wpDemosBaseUrl } from "../../store";
import AreaChart from "../Charts/AreaChart";
import ComboChart from "../Charts/ComboChart";
import Footer from "../Footer/footer";
import Header from "../Header/header";
import Historical from "../Historical/Historical";
import Recomendation from "../Recomendation";
import ToolTip from "../ToolTip";
import HistoricalBarChart from "./HistoricalBarChart";
import HistoricalCalendarChart from "./HistoricalCalendarChart";
import LeftSidebar from "./leftSidebar";
import OverView from "./overview";
import LoadingSpinner from "./LoadingSpinner";

const DetailPage = () => {
    // const [state, setState] = useState({});
    let { id } = useParams();
    // let id='5fdb0bc345047a4d3d83d677'
    const dispatch = useDispatch();
    const [singleLocitem, setSingleLocitem] = useState(null)
    const [selectedPM, setSelectedPM] = useState('pm25')
    const [selectedYear, setSelectedYear] = useState('')
    // const [state, setState] = useState({ hourlyDataPm10: [], hourlyDataPm25: [] })
    const { singleItem, loading, locations, dailyData, years } = useSelector((state) => state.getapiData);

    useEffect(() => {
        ReactTooltip.rebuild();
        getLocations();
        getYearsList();
    }, []);

    useEffect(() => {
        if (locations.length > 0) {
            const _singleLocitem = locations.find((item) => {
                if (item.url == id) {
                    return item
                }
            })
            setSingleLocitem(_singleLocitem);
        }
        
    }, [id,locations]);
useEffect(()=>{
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Scroll smoothly
      });
},[id])
    useEffect(() => {
        if (years && years.length > 0) {
            let defaultYear = years[0]?.year;
            setSelectedYear(defaultYear);
            getDailyData(defaultYear);
        }
    }, [years, id])
    


    useEffect(() => {
        if (singleLocitem) {
            dispatch({
                type: "getapiData/getAlldata",
                payload: { url: id, lat: singleLocitem.lat, lang: singleLocitem.lang, sponseredbybok: singleLocitem.sponseredbybok, singlRecord: true },
            });
        }
        return () => {
            /*   console.log("from effect");
              dispatch({ type: "records/resetRecords" }); */
        };
    }, [singleLocitem]);

    const getLocations = () => {
        dispatch({
            type: "getapiData/getAlldata",
            payload: {
                url: `${wpBaseUrl}/moniterlocation?per_page=100`,
                setType: 'getapiData/setLocations',
                fromwp: true
            }
        });
    }

    const getYearsList = () => {
        dispatch({
            type: "getapiData/getAlldata",
            payload: {
                url: `${wpDemosBaseUrl}/aqi-all-years/v2/api/years`,
                setType: 'getapiData/setYears',
                fromwp: true
            }
        });
    }

    const getDailyData = (year) => {
        dispatch({
            type: "getapiData/getAlldata",
            payload: {
                // url: `${wpDemosBaseUrl}/aqi-new-data/v2/api/${id}/daily`,
                url: `${wpDemosBaseUrl}/aqi-yearly-data/v2/api/${id}/daily/${year}`,
                setType: 'getapiData/setDailyData',
                fromwp: true
            }
        });
    }

    const onYearChange = (year) => {
        setSelectedYear(year)
        // console.log("🚀 ~ file: detailPage.js ~ line 97 ~ onYearChange ~ year", year)
        getDailyData(year)
    }

    const locationName = id == '5fdb0bc345047a4d3d83d677' ? singleItem?.settings?.node_name : singleLocitem?.placename; //singleItem?.name

    return (
        <>
        <LoadingSpinner show={loading} />
            <Header />
            <ReactTooltip id="tooltip" className="tooltipContent" />
            <div className="px-2">
                <div className="container">
                    <div className="row my-4">
                        <div className="col-md-12 location-breadcum">
                            <span className="station">Station</span>
                            <span> World &gt; Pakistan &gt; Khyber Pakhtunkhwa &gt; {locationName} - PCAA</span>
                        </div>
                        <div className="col-md-12 loc-name-heading">
                            <h2>Air Quality in Khyber Pakhtunkhwa</h2>
                            <p>Air Quality Index <ToolTip className="font-weight-bold" content={tooltipContent.whatIsAQI} label={'(AQI)'} />, <ToolTip className="font-weight-bold" content={tooltipContent.pm25} label={'PM2.5'} />, and <ToolTip className="font-weight-bold" content={tooltipContent.pm10} label={'PM10'} /> concentration near {locationName}</p>
                            {/* <p>Air quality near <span > {id == '5fdb0bc345047a4d3d83d677' ? singleItem.settings?.node_name : singleItem?.name}</span>, Peshawar</p>
                                <p>Air quality index (AQI) and PM2.5 <span > {id == '5fdb0bc345047a4d3d83d677' ? singleItem.settings?.node_name : singleItem?.name}</span></p> */}
                            {singleItem && <p><span className="lastupdate">Last Update</span> at {new Date(singleItem?.current.ts).toLocaleString()}</p>}
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            {singleLocitem && <LeftSidebar data={singleItem} location={singleLocitem} />}
                        </div>
                        <div className="col-md-8">

                            {id == '5fdb0bc345047a4d3d83d677' ? <OverView overviewData={{ time: singleItem?.current?.ts, aqi: singleItem?.historical?.hourly[0]?.outdoor_station?.aqius, pm25: singleItem?.historical?.hourly[0]?.outdoor_station?.aqicn, pm10: singleItem?.historical?.hourly[0]?.outdoor_station?.aqicn, name: singleItem?.settings?.node_name }} /> :
                                <OverView overviewData={{ time: singleItem?.current?.ts, aqi: singleItem?.current?.aqius, pm25: singleItem?.current?.pm25?.conc, pm10: singleItem?.current?.pm10?.conc, name: singleItem?.name }} />}
                            <div className="recomendatios-main-div">
                                <h5 className="heading">HEALTH RECOMMENDATIONS</h5>
                                <p className="text">Ways to protect your health when particle pollution reaches unhealthy levels.</p>
                                {id == '5fdb0bc345047a4d3d83d677' ? <Recomendation aqivalue={singleItem?.historical?.hourly[0]?.outdoor_station?.aqius} /> :
                                    <Recomendation aqivalue={singleItem?.current?.aqius} />}

                            </div>
                            <div className="aqichart-main-div">
                                <div className="mt-4 p-md-4 p-2 bg-light">
                                    <p className="m-0">
                                        <Link className="text-decoration-none linkColour">HISTORICAL</Link>
                                    </p>
                                    <p className="text-bold fs-6">
                                        <b>
                                            {singleItem?.name} air qaulity index <span className="font-weight-bold" data-for="tooltip" data-tip={tooltipContent.whatIsAQI}>(AQI)</span> forecast
                                        </b>
                                    </p>
                                    {singleItem && <Historical singleItem={singleItem} id={id} />}
                                </div>
                                <div className="mt-4 p-md-4 p-2 bg-light">
                                    <p className="m-0">
                                        <Link className="text-decoration-none linkColour">CURRENT AQI/PM</Link>
                                    </p>
                                    <p className="fw-bold">Hourly Air Quality Index <ToolTip className="font-weight-bold" content={tooltipContent.whatIsAQI} label={'(AQI)'} />, <ToolTip className="font-weight-bold" content={tooltipContent.pm25} label={'PM2.5'} /> and <ToolTip className="font-weight-bold" content={tooltipContent.pm10} label={'PM10'} /> Concentration Values in Y Axis</p>
                                    <hr />
                                    <p className="text-center mb-0">
                                        Updated at <b></b>
                                    </p>
                                    <p className="text-center">{new Date(singleItem?.current?.ts).toLocaleString()}</p>
                                    {singleItem && <ComboChart yAxisTitle="PM 2.5" seriesName="PM 2.5" singleItem={singleItem} pm={'2.5'} id={id} xAxisLabel="Hourly" />}
                                    {singleItem && <ComboChart yAxisTitle="PM 10" seriesName="PM 10" singleItem={singleItem} pm={'10'} id={id} xAxisLabel="Hourly" />}
                                </div>
                                <div className="bg-light mt-4 p-4">
                                    <div className="d-flex justify-content-between">
                                        <Link className="text-decoration-none linkColour">
                                            HISTORICAL DATA -  {selectedYear}
                                        </Link>
                                        <div>
                                            <select className="form-controls mr-2" onChange={({ target }) => setSelectedPM(target.value)}>
                                                <option value="" disabled>Select PM</option>
                                                <option value="pm25" selected>PM2.5</option>
                                                <option value="pm10">PM10</option>
                                            </select>
                                            <select className="form-controls" onChange={({ target }) => onYearChange(target.value)}>
                                                <option value="">Select Year</option>
                                                {years && years.filter(({year})=>year && year).map(({ year }, i) => <option key={i} value={year} selected={i == 0}>{year}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <p className="fw-bold">Historical Daily <span className="font-weight-bold" data-for="tooltip" data-tip={tooltipContent.whatIsAQI}>(AQI)</span> Values</p>
                                    <hr />
                                    {dailyData && <HistoricalCalendarChart historicalData={dailyData} chartDataType={selectedPM} yAxisLabel={`Year ${selectedYear}`}/>}
                                    <div className="d-flex justify-content-center align-items-center">
                                        {dailyData && <HistoricalBarChart historicalData={dailyData} chartDataType={selectedPM} yAxisLabel={`Year ${selectedYear}`}/>}
                                    </div>
                                    <Link className="text-decoration-none linkColour">Daily MIN-MAX</Link>
                                    <p className="mb-0">Types of air quality days by month</p>
                                    <p>Daily Minimum and Maximum Air Quality Index <span className="font-weight-bold" data-for="tooltip" data-tip={tooltipContent.whatIsAQI}>(AQI)</span> values
                                    </p>
                                    <AreaChart id={id} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default DetailPage