import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMap, Marker, Popup, ZoomControl } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import SearchableDropdown from "../SearchableDropdown";
import Export from "../Export";
import { wpBaseUrl } from "../../store";
import ColorBar from "../Home/colorBar";
import MultipleMarkers from "../Home/multipleMarker";
import MapMultipleMarkers from "../Home/mapMultipleMarkers";

const MapPage = () => {
       const dispatch = useDispatch();
       const { locations } = useSelector((state) => state.getapiData);
       const [popStatus, setPopStatus] = useState(false)
       // console.log("🚀 ~ file: homePage.js ~ line 17 ~ MapPage ~ locations", locations)

       useEffect(() => {
              getLocations();
       }, []);

       useEffect(() => {
              if (locations.length > 0) {
                     getAllLocationsData()
              }
       }, [locations]);

       const getLocations = () => {
              dispatch({
                     type: "getapiData/getAlldata",
                     payload: {
                            url: `${wpBaseUrl}/moniterlocation?per_page=100`,
                            setType: 'getapiData/setLocations',
                            fromwp: true
                     }
              });
       }

       const getAllLocationsData = () => {
              locations.map((loc) => {
                     dispatch({
                            type: "getapiData/getAlldata",
                            payload: {
                                   url: loc.url,
                                   lat: loc.lat,
                                   lang: loc.lang,
                                   sponseredbybok: loc.sponseredbybok,
                                   singlRecord: false
                            }
                     });
              });
       }

       const onMarkerMouseOver = (status) => {
              setPopStatus(status)
       }

       return (
              <div className="position-relative">
                    
                     {!popStatus && <SearchableDropdown ismap={true} />}

                     <MapContainer
                            center={[34.0033652, 71.4959851]}
                            zoom={8}
                            style={{ minHeight: '100vh', height: "100%" }}
                            scrollWheelZoom={false}
                            zoomControl={false}
                     >
                            <div className="map-content">
                                   <h5 className="font-weight-bold">Air Quality in Khyber Pakhtunkhwa</h5>
                                   <p className="mb-0">Explore the air quality data from our monitors in different areas of Peshawar and KP</p>
                            </div>
                            <Export />
                            <ZoomControl position="bottomright" />
                            <TileLayer
                                   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <MapMultipleMarkers onMouseOver={(status)=>onMarkerMouseOver(status)}/>
                     </MapContainer>
                     <div className="home-colorbag"><ColorBar /></div>

                    
              </div>
       );
};

export default MapPage;
