import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getcolorclass } from "../constants";
const Recomendation = (props) => {
    const { aqivalue } = props;

    const dispatch = useDispatch();
    const url = getcolorclass('recomendationurl', aqivalue);

    const { recomendationItem } = useSelector((state) => state.getapiData);
    console.log(recomendationItem,'recomendationItem');
    useEffect(() => {
        if (url && aqivalue) {
            dispatch({
                type: "getapiData/getAlldata",
                payload: { url: url, fromwp: true },
            });
        }
        return () => {
             dispatch({ type: "records/resetRecords" }); 
        };
    }, [url,aqivalue]);



    return (
        <>
            <div className="container ">
                <div className="row">
                    {recomendationItem?.acf.recomendation_1 && <div className="col-md-6">
                        <div className="recomendation">
                            {recomendationItem?.acf.recommendation_1_icon ? <img src={recomendationItem?.acf.recommendation_1_icon} /> : ''}
                            <p className="text">{recomendationItem?.acf.recomendation_1}</p>
                        </div>
                    </div>}
                    {recomendationItem?.acf.recommendation_2 && <div className="col-md-6">
                        <div className="recomendation">
                            {recomendationItem?.acf.recommendation_2_icon ? <img src={recomendationItem?.acf.recommendation_2_icon} /> : ''}
                            <p className="text">{recomendationItem?.acf.recommendation_2}</p>
                        </div>
                    </div>}
                </div>
                <div className="row">
                    {recomendationItem?.acf.recommendation_3 && <div className="col-md-6">
                        <div className="recomendation">
                            {recomendationItem?.acf.recommendation_3_icon ? <img src={recomendationItem?.acf.recommendation_3_icon} /> : ''}
                            <p className="text">{recomendationItem?.acf.recommendation_3}</p>
                        </div>
                    </div>}
                    {recomendationItem?.acf.recommendation_4 && <div className="col-md-6">
                        <div className="recomendation">
                            {recomendationItem?.acf.recommendation_4_icon ? <img src={recomendationItem?.acf.recommendation_4_icon} /> : ''}
                            <p className="text">{recomendationItem?.acf.recommendation_4}</p>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}

export default Recomendation