import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  items: [],
  error: '',
  loading: false,
  locations: [],
  dailyData: [],
  isSingleloading: true
};

export const getapiDataSlice = createSlice({
  name: "getapiData",
  initialState,
  reducers: {
    getAlldata(state, action) {
      return { ...state, loading: true, items: [] };
    },
    setLocations(state, action) {
      let _locations = action.payload.data;
      _locations = _locations.filter((location) => location?.acf?.active_status && location?.acf?.active_status).map((location) => ({ ...location, ...location?.acf }))
      return { ...state, loading: false, locations: _locations };
    },
    setYears(state, action) {
      return { ...state, loading: false, years: action.payload.data };
    },
    setRecords(state, action) {
      if (action.payload.fromwp) {
        return { ...state, loading: false, recomendationItem: action.payload.data };
      } else {
        return { ...state, loading: false, items: [...state.items, { ...action.payload, id: action.id, lat: action.lat, lang: action.lang, sponseredbybok: action.sponseredbybok }] };
      }
    },
    setSingleRecord(state, action) {
      return { ...state, loading: false, singleItem: { ...action.payload, id: action.id, lat: action.lat, lang: action.lang, sponseredbybok: action.sponseredbybok } };
    },
    setDailyData(state, action) {
      return { ...state, loading: false, dailyData: action.payload.data };
    },
    setErrors(state, action) {
      return { ...state, loading: false, error: action.payload };
    },
    resetData(state, action) {
      return { ...state, items: [], item: {} };
    },
    setLoading(state,action){
      return {...state, loading:action.payload};
    }

  }
})

export const { setSingleRecord, setRecords, getAlldata, setErrors, resetData ,setLoading} = getapiDataSlice.actions;

export default getapiDataSlice.reducer;


