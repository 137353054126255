import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";

import { watcherSaga } from "./sagas";
import  getapiDataSlice  from "./reducers/getapiDataSlice";


const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
    getapiData: getapiDataSlice,
});

const store = configureStore({
  reducer,
  middleware: [sagaMiddleware]//logger],
});

sagaMiddleware.run(watcherSaga);

//export const baseUrl = "http://203.124.35.18:8124";
export const baseUrl = "https://device.iqair.com/v2/";
export const wpBaseUrl = "https://pcaakp.org/wp-json/wp/v2";
export const wpDemosBaseUrl = "https://pcaakp.org/wp-json";

export default store;
