import React from 'react';

class LoadingSpinner extends React.Component {
  render() {
    return (
      <div className="modal spinermodel" style={{ display: this.props.show ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <div className="spinner-border textnew" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoadingSpinner;
