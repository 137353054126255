import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";

import HomePage from "./components/Home/homePage";
import DetailPage from "./components/Details/detailPage";
import MapPage from "./components/Details/mapPage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/detailpage/:id" element={<DetailPage />} />
      </Routes>
    </div>
  );
}

export default App;
