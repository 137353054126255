const Footer = () => {
  return (
    <footer className="section pagefooter context-dark bg-image">
      <div className="container main-footer footerResponsivess">
        <div className="row row-30">
          <div className="col-md-5 footer-sec">
            <div className="pr-xl-4">
              <h2>Peshawar Clean Air Alliance</h2>
              <p>
                The Peshawar Clean Air Alliance is a volunteer association of
                civil society individuals and groups sharing a common vision of
                improving Peshawar’s air quality. We need your support in our
                earnest efforts to ensure a better, cleaner environment.
              </p>
              <div className="footer-social-links">
                <a href="https://twitter.com/PCAAKP" target="_blank" rel="noreferrer">
                  <img src="https://pcaakp.org/wp-content/uploads/2021/12/fb.png" />
                </a>
                <a href="https://twitter.com/PCAAKP" target="_blank" rel="noreferrer">
                  <img src="https://pcaakp.org/wp-content/uploads/2021/12/twitter.png" />
                </a>

                <a href="mailto:contact@pcaakp.org">
                  <img
                    decoding="async"
                    src="https://pcaakp.org/wp-content/uploads/2022/05/email.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-1 footer-sec"></div>
          <div className="col-md-2 footer-sec">
            <div className="pr-xl-4">
              <h2>About</h2>
              <ul>
                <li><a href="https://pcaakp.org/what-we-do/">What we Do</a></li>
                <li><a href="https://pcaakp.org/#">Stories</a></li>
              </ul>
            </div>
          </div>

          <div className="col-md-2 footer-sec">
            <div className="pr-xl-4">
              <h2>Resources</h2>
              <ul>
                <li><a href="https://pcaakp.org/resources">Blogs</a></li>
                <li><a href="https://pcaakp.org/resources">Publications</a></li>
              </ul>
            </div>
          </div>

          <div className="col-md-2 footer-sec">
            <div className="pr-xl-4">
              <h2>Join Us</h2>
              <ul>
                <li><a href="https://pcaakp.org/join-us/">Register</a></li>
                <li><a href="https://pcaakp.org/donate/">Donate</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_copy">
        <div className="container">
          <div className="column one">
            <div className="copyright">
              <span className="copyrights-sec-left">
                © 2022 Peshawar Clean Air Alliance. All Rights Reserved. Website
                Powered by{" "}
                <a href="https://centangle.com" target="_blank" rel="noreferrer">
                  Centangle Interactive
                </a>
              </span>
              <span className="copyrights-sec-mid">
                <a href="https://pcaakp.org/privacy-policy/">Privacy Policy</a>
              </span>
              <span className="copyrights-sec-right">
                <a href="https://pcaakp.org/terms-service/">
                  Terms &amp; Service
                </a>
              </span>{" "}
            </div>

            <ul className="social"></ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
