import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const SearchableDropdown = ({ismap}) => {
  const navigate = useNavigate();
  const { items } = useSelector((state) => state.getapiData);

  const handleChange = (selectedOption) => {
   if(ismap)
    window.open(`/detailpage/${selectedOption.id}`, '_blank');
    else
    {
      navigate("/detailpage/" + selectedOption.id);
    }
  };

  return (
    <div className="selectBox">
      <Select
        placeholder="Enter your location"
        className="dropdown-input"
        options={items}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        onChange={handleChange}
      />
    </div>
  );
};

export default SearchableDropdown;
