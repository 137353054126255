/* eslint-disable eqeqeq */


export const getcolorclass = (type, value) => {
    let class_name = '', class_text = '', faceIcon = '', recomendationurl = '';
    if (value >= 0 && value <= 50) {
        class_name = 'good'
        class_text = 'Good'
        faceIcon = <i className="bi bi-emoji-smile"></i>;
        recomendationurl = 'https://pcaakp.org/wp-json/wp/v2/aqirecomendationn/1792'
    }
    if (value > 50 && value <= 100) {
        class_name = 'moderate'
        class_text = 'Moderate'
        faceIcon = <i className="bi bi-emoji-neutral"></i>;
        recomendationurl = 'https://pcaakp.org/wp-json/wp/v2/aqirecomendationn/1801'
    }
    if (value > 100 && value <= 150) {
        class_name = 'unhealthy-for'
        class_text = 'Unhealthy for sensitive group'
        faceIcon = <i className="bi bi-emoji-expressionless"></i>;
        recomendationurl = 'https://pcaakp.org/wp-json/wp/v2/aqirecomendationn/1804'
    }
    if (value > 150 && value <= 200) {
        class_name = 'unhealthy';
        class_text = 'Unhealthy';
        faceIcon = <i className="bi bi-emoji-frown"></i>;
        recomendationurl = 'https://pcaakp.org/wp-json/wp/v2/aqirecomendationn/1808'
    }
    if (value > 200 && value <= 300) {
        class_name = 'v-unhealthy';
        class_text = 'very Unhealthy';
        faceIcon = <i className="bi bi-emoji-angry"></i>;
        recomendationurl = 'https://pcaakp.org/wp-json/wp/v2/aqirecomendationn/1811'

    }
    if (value > 300) {
        class_name = 'hazardous';
        class_text = 'Hazardous';
        faceIcon = <i className="bi bi-emoji-dizzy"></i>;
        recomendationurl = 'https://pcaakp.org/wp-json/wp/v2/aqirecomendationn/1813'
    }
    if (type == 'recomendationurl') {
        return recomendationurl
    }
    if (type == 'color') {
        return class_name
    }
    if (type == 'text') {
        return class_text
    }
    if (type == 'icon') {
        return faceIcon
    }

}

export const tooltipContent = {
    whatIsAQI: "The U.S. Air Quality Index, or AQI, is a tool for reporting and communicating daily air quality. It uses color-coded categories and provides statements for each category that tell you about air quality in your area, which groups of people may be affected, and steps you can take to reduce your exposure to air pollution. The higher the AQI value, the greater the level of air pollution and the greater the health concern.",
    pm25: "PM2.5 : fine inhalable particles, with diameters that are generally 2.5 micrometers and smaller.",
    pm10: "PM10 : inhalable particles, with diameters that are generally 10 micrometers and smaller."
}

export const GradeType = {
    GOOD: 'GOOD',
    MODERATE: 'MODERATE',
    UNHEALTHY_SENSITIVE: 'UNHEALTHY_SENSITIVE',
    UNHEALTHY: 'UNHEALTHY',
    VERY_UNHEALTHY: 'VERY_UNHEALTHY',
    HAZARDOUS: 'HAZARDOUS',
}

